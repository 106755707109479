<template>
  <div>
    <div class='summary'>
      <div class='left'>
        <el-tag effect='plain' type='success' class=''>{{ userPermissions.indexOf('pa_all_mcn') > -1 ? '数据范围： 全部' : ''
          }}
        </el-tag>
      </div>
      <div class='summary-panel'>
        共计 <span class='summary-num'>{{ countKol }}</span>个考核红人 <span class='summary-num'>{{ dataList.length
        }}</span> 考核项
      </div>
    </div>

    <div class='default-table' style='padding-bottom: 60px'
         v-loading='loadingStatus'
         :element-loading-text='loadingText'
         element-loading-spinner='el-icon-loading'>
      <el-table
        :key='`paRevenue_${editMode?"edit":"view"}`'
        :data='dataList'
        :span-method='objectSpanMethod'
        @row-contextmenu='rowContextmenu'
        :cell-class-name='cellClassName'
        style='width: 100%'
        border>
        <el-table-column
          prop='nickname'
          label='红人昵称'
          align='center'
          min-width='110'>
          <template slot-scope='{row}'>
            <artist-nickname :info='row' />
            <div class='dept_name'>{{ row.dept_name || '' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop='platform_name'
          label='平台'
          align='center'
          min-width='90'>
          <template slot-scope='{row}'>
            <span>{{ row.platform_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop='income_type'
          label='收入分类'
          align='center'
          min-width='90'>
        </el-table-column>
        <el-table-column
          prop='average_value'
          label='上季月营收均值'
          min-width='110' header-align='center' align='center'>
          <template slot-scope='scope'>
            <span>{{ (scope.row[`average_value`]) }}</span>
          </template>
        </el-table-column>
        <el-table-column v-for='(month,index) in quarterlyMonths' :key='index' :label='`${month}月`'
                         align='center'>
          <el-table-column
            :prop='`quotient_${month}`'
            label='系数'
            min-width='100' header-align='center' align='center'>
            <editable-cell
              v-if='editMode'
              :show-input='row.editMode'
              slot-scope='{$index,row,column}'
              v-model='row[column.property]'
              @change='editVal(row,column.property,$index)' :is-input-number='false'>
                          <span slot='content'>
                            <span>{{ row[column.property] || 0 }}</span>
                             <i class='el-icon-edit-outline default-edit-icon'></i>
                          </span>
            </editable-cell>
            <template slot-scope='{row,column}' v-else>
              <span>{{ row[column.property] || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :prop='`flag_value_${month}`'
            label='目标'
            min-width='100' header-align='center' align='center'>
            <editable-cell
              v-if='editMode'
              :show-input='row.editMode'
              slot-scope='{$index,row,column}'
              v-model='row[column.property]'
              @change='editVal(row,column.property,$index)' :is-input-number='false'>
                          <span slot='content'>
                            <span>{{ row[column.property] || 0 }}</span>
                             <i class='el-icon-edit-outline default-edit-icon'></i>
                          </span>
            </editable-cell>
            <template slot-scope='{row,column}' v-else>
              <span>{{ row[column.property] || 0 }}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

    </div>
    <!--    右键菜单-修改红人考核月份-->
    <RightMenu :menu-data='menuData' @change='selectContextmenu' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { numberFormat } from '@/utils/utils'
import EditableCell from '@/components/EditableCell'
import RightMenu from '@/pages/pa/components/RightMenu.vue'

export default {
  name: 'PaRevenue',
  components: { RightMenu, EditableCell },
  data() {
    return {
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      searchCondition: {},
      tipsList: [
        '目标值 = 上季月均值 * 系数（若系数为“/”，则可手动输入目标值，前提：有录入权限）',
        '上季涨粉均值：求上季度三个月的新增粉丝数/3，并四舍五入取整',
        '数据录入：数据录入后，实时保存，但是其他人不可见，需要发布后才能同步给其他人',
        '数据确认：1)【确认发布】后数据，其他人即可看到最新数据 2)【确认发布】后若继续修改数据，需要重新点击发布其人才能看到最新数据',
        '设置考核：1) 单个设置，右键“红人昵称/平台”，可进行配置；2) 整组考核设置，点击右上角“考核设置”',
        '批量修改系数：在编辑页面，右键列“系数”，可进行修改',
        '数据版本：已发布/编辑中（有系数录入权限的人才能看[编辑中]的数据）',
        '自营电商-完成值： 电商数据-店铺数据的月度总支付金额 * 0.03（系数），即：月总支付金额：10000，则完成值： 300（10000*0.03）。'
      ],
      showIndex: false,//显示序号
      loadingStatus: false,
      showEditIcon: true,
      commitLoading: false,
      edit_mode: 'add',
      // searchCondition: {
      //   months: []
      // },
      bonus_items: [],//团队奖金
      dataList: [],
      mergeFields: ['nickname', 'platform_name'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      fixFields: ['group_name', 'platform_name', 'income_type', 'average_value'],
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      calcFields: [],
      bonusRow: { nickname: '团队奖金', platform_name: '团队奖金', income_type: '团队奖金', average_value: '团队奖金' },//团队奖金行的可编辑列
      currentRow: {},
      currentColumn: {},
      dialogQuotientVisible: false,
      formQuotient: {
        quotient: 0,
        month: 0,
        year: 0,
        group_id: ''
      },
      mode: 'drawer',
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '90%',
        show_footer: false
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      logSetting: {
        visible: false
      }, performanceSetting: {
        visible: false
      },
      checkMonthList: []
    }
  },
  props: {
    editMode: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    groupName() {
      return this.searchCondition.group_name
    },
    loadingText() {
      return `【${this.searchCondition.year}】年第【${this.searchCondition.quarter}】季度的营收绩效目标数据加载中...`
    },
    quarterlyMonths() {
      const quarter = this.searchCondition.quarter

      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3]
    },
    // 红人统计
    countKol() {
      let kolArr = []
      this.dataList.forEach((item) => {
        if (item.artist_id) {
          if (kolArr.indexOf(item.artist_id) === -1) {

            kolArr.push(item.artist_id)
          }

        }
      })
      return kolArr ? kolArr.length : 0
    }
    // tblData() {
    //   return this.dataList || []
    // }
    // formQuotientTitle() {
    //   return `批量修改【${ this.formQuotient.year }年${ this.formQuotient.month }月】【${ this.searchCondition.group_name }】营收绩效系数`
    // }
  },
  methods: {
    show(searchCondition) {
      this.searchCondition = searchCondition
      this.getList()
    },
    clear(query) {
      this.searchCondition = query
      this.dataList = []
    },
    //========= 【右键菜单选中事件 ================
    // 表格右键事件
    async rowContextmenu(row, column, e) {
      e.preventDefault()//阻止元素发生默认的行为
      this.currentRow = row
      this.currentColumn = column
      // console.log(`红人：${row.nickname},平台：${row.platform_name},列：${column.property}`)
      // if (this.userPermissions.indexOf('performance_single_setting') > -1 && ['nickname'].indexOf(column.property) > -1) {
      if (this.editMode && (['platform_name', 'income_type'].indexOf(column.property) > -1 || column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1)) {

        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary

        // const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top // container margin left

        // const left = e.clientX - offsetLeft // 15: margin right
        const left = e.clientX  // 15: margin right
        if (left > maxLeft) {
          this.left = maxLeft
        } else {
          this.left = left
        }
        const top = e.clientY - offsetTop
        // this.$notify.success(`top:${top},left:${left},平台：${row.platform_name}`)
        //完成值列-右键事件
        this.openCheckMenu(row, column, left, top)

      }
    },
    // 右键菜单选中事件
    selectContextmenu(val) {
      const column = this.currentColumn
      let row = {
        'year': this.currentRow.year,
        'quarter': this.currentRow.quarter,
        'artist_id': this.currentRow.artist_id,
        'nickname': this.currentRow.nickname,
        'platform_code': this.currentRow.platform_code,
        'platform_name': this.currentRow.platform_name,
        'need_check': val
      }
      //右键菜单选中
      if (column.property === 'platform_name') {
        // console.log(row)
        this.handleChangeSingleCheck(row)
      } else if (column.property === 'income_type') {
        row['income_code'] = this.currentRow.income_code
        row['income_type'] = this.currentRow.income_type
        // console.log(row)
        this.handleChangeSingleCheck(row)
      } else {
        if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
          let arr = this.currentColumn.property.split('_')
          let month = parseInt(arr[arr.length - 1])//数据类型：整型
          let colName = `need_check_${month}`
          this.currentRow[colName] = val
          let index = this.dataList.indexOf(this.currentRow)
          if (index > -1) {
            this.dataList[index][colName] = val

            row['income_code'] = this.currentRow.income_code
            row['income_type'] = this.currentRow.income_type
            row['month'] = month
            // console.log(row)
            this.handleChangeSingleCheck(row)
          }
        }
      }
    },
    //显示自定义右键菜单
    openCheckMenu(row, column, clientX, clientY) {
      // let index = row.index
      // console.log(`open:${JSON.stringify(row)}`)
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
      this.menuData = {
        visible: true,
        left: clientX - offsetLeft,
        top: clientY
      }
      document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      // document.addEventListener('click', this.closeMenu)
      // // if (index <= this.dataList.length) {
      //   if (column.property.indexOf('platform_name') > -1 ||column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
      //     this.currentRow = row
      //     this.currentColumn = column
      //
      //     document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      //   }
      // }
    },
    //右键菜单：设置是否月考核
    // eslint-disable-next-line no-unused-vars
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      this.menuData.visible = false
      // if (e.target.className.indexOf('contextmenu__item') === -1) {
      //   this.menuData.visible = false
      //   document.removeEventListener('click', this.closeMenu)
      // }
    },
    async handleChangeCheck(data) {
      await this.$api.changePaKolRevenueQuarterNeedCheck(data)
      // this.$notify.success('已设置')
      await this.getList()
    },
    handleChangeSingleCheck(row) {
      this.handleChangeCheck({ single: row })
    },
    //========= 右键菜单选中事件 】================


    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    async saveCell(postData) {
      await this.$api.savePaKolRevenueData(postData)
    },
    // eslint-disable-next-line
    editVal(row, colName, rowIndex) {
      console.log(`当前操作第${rowIndex},列${colName},isNaN:${isNaN(row[colName])}`)
      let saveData = {
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        artist_id: row.artist_id,
        platform_code: row.platform_code,
        income_code: row.income_code,
        dept_id: row.dept_id
        // income_type: row.income_type,
      }
      const val = row[colName]
      if (colName.indexOf('quotient_') > -1) {
        let arr = colName.split('quotient_')
        let month = Number(arr[1] || 0)
        if (month >= 1 && month <= 12) {
          saveData['month'] = month
          // let syncField = `quotient_${saveData['month']}`
          if (val !== '/') {
            if (!isNaN(val)) {
              saveData['quotient'] = val
              saveData['flag_value'] = val * row['average_value']
              this.dataList[rowIndex][`flag_value_${month}`] = saveData['flag_value']
              this.saveCell(saveData)
            }
          } else {
            saveData['quotient'] = -1
            this.saveCell(saveData)
          }
        }
      } else if (colName.indexOf('flag_value_') > -1) {
        let arr = colName.split('flag_value_')
        let month = Number(arr[1] || 0)
        if (month >= 1 && month <= 12) {
          saveData['month'] = month
          saveData['flag_value'] = val
          this.saveCell(saveData)
        }
      }

      // if (!isNaN(row[colName])) {
      //   row[colName] = row[colName] * 1
      //   //修改系数值-同步计算目标值
      //   if (colName.indexOf('quotient_') > -1) {
      //     let arr = colName.split('_')
      //     let syncField = `flag_value_${arr[1]}`
      //     row[syncField] = row[colName] * row['average_value']
      //   }
      //   //团队奖金数据同步，重新计算表格数据的时候要使用
      //   if (colName.indexOf('amount_') > -1) {
      //     this.bonusRow[colName] = row[colName]
      //     this.saveBonus(row, colName)
      //   } else {
      //     //实时保存数据
      //     this.save(row, colName)
      //   }
      // }

    },
    //保存
    async save(row, colName) {
      await this.$api.saveAPerformanceRevenue({
        search_condition: this.searchCondition,
        data: {
          row: row,
          col_name: colName
        }
      })
    },
    /**
     * 保存-团队奖金
     * @param row
     * @param colName
     * @returns {Promise<void>}
     */
    async saveBonus(row, colName) {
      let arr = colName.split('_')
      let month = arr[1]
      await this.$api.saveBonus({
        search_condition: this.searchCondition,
        data: { type: 'revenue', amount: row[colName], month: month }
      })
    },
    async getList() {
      this.getCalcFields()
      this.dataList = []
      //this.searchCondition.dept_ids && this.searchCondition.dept_ids.length > 0 &&
      if ( this.searchCondition.year && this.searchCondition.quarter) {
        this.loadingStatus = true
        let { list } = await this.$api.getPaKolRevenueQuarterData(this.searchCondition)
        this.dataList = list
        this.loadingStatus = false
        this.rowspan() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
      }

    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.spanObj = {}
      this.positionObj = {}
      if (this.dataList && this.dataList.length > 0) {
        this.dataList.forEach((item, index) => {
          fields.forEach((field) => {
            if (index === 0) {
              this.spanObj[field] = []
              this.spanObj[field].push(1)
              this.positionObj[field] = 0
            } else {
              // 合并数据：若和上一行的数据相同，则该字段合并行数加1
              if (this.dataList[index][field] === this.dataList[index - 1][field]) {
                if (field === 'platform_name') {
                  //平台列，特殊处理
                  if (this.dataList[index]['nickname'] === this.dataList[index - 1]['nickname']) {
                    this.spanObj[field][this.positionObj[field]] += 1
                    this.spanObj[field].push(0)
                  } else {
                    this.spanObj[field].push(1)
                    this.positionObj[field] = index
                  }
                } else {
                  this.spanObj[field][this.positionObj[field]] += 1
                  this.spanObj[field].push(0)
                }

              } else {
                this.spanObj[field].push(1)
                this.positionObj[field] = index
              }
            }
          })
        })
      }

    },
    // 表格合并行

    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      const fields = this.mergeFields
      if (column.property && fields.indexOf(column.property) > -1) {
        const _row = this.spanObj[column.property][rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    getCalcFields() {
      this.calcFields = []
      this.calcFields.push('average_value')
      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.quarterlyMonths.forEach((month) => {
          this.bonusRow[`amount_${month}`] = 0//团队奖金行数据初始化

          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1
              }
            })
          }
        })
      }
      return calcRow
    },

    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({ row, column, rowIndex, columnIndex }) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
          // console.log('fieldNeedCheck', row[fieldNeedCheck])
        }
      }
      if (unCheckBool) {
        return 'cell-uncheck'
      }
    },
    async changeCheckMonth(month) {

      let params = {
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        month: month,
        check_status: this.checkMonthList.indexOf(month) > -1 ? 'Y' : 'N'
      }
      let info = await this.$api.setPerformanceCheckedMonth(params)
      if (info === 'ok') {
        this.$notify.success('操作成功')
      }
    },
    async getCheckMonths() {
      let { months } = await this.$api.getPerformanceCheckedMonths({
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        months: this.quarterlyMonths
      })
      //当前已核算完成的月份
      this.checkMonthList = [...months]
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    },
    showQuarterReal() {

    },
    //获取红人集合（用于批量设置限定范围）
    getCurrKolRange() {
      let kolRage = []
      this.dataList.forEach((item) => {
        if (kolRage.indexOf(item.artist_id) === -1)
          kolRage.push(item.artist_id)
      })
      return kolRage
    }
  }
}
</script>

<style scoped>
.default-edit-icon {
  float: right;
}
</style>
