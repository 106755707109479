<template>
  <div>
    <div class='main-page-content'>
      <div class='flex-sb' style='align-items: center'>
        <el-form :model='searchCondition' :inline='true' size='mini'>
          <el-form-item>
            <el-date-picker
              v-model='searchCondition.year'
              type='year'
              format='yyyy年'
              value-format='yyyy'
              placeholder='选择年度' @change='changeYear' style='width: 140px'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <QuarterSelect size='mini' :quarter.sync='searchCondition.quarter'
                           @change='handleChangeQuarter'></QuarterSelect>
          </el-form-item>
          <el-form-item>
            <DeptCascader ref='refCompanySelect' :multiple='true' @handleSelect='selectDept'></DeptCascader>
          </el-form-item>
<!--          <el-form-item>-->
<!--            <div style='display: flex;flex-direction: row;gap: 5px'>-->
<!--              <McnDeptSelect ref='deptSelect' :multiple='true' @handleSelect='selectDept' @loaded='loadDeptSetting'-->
<!--                             :search-condition='searchCondition'></McnDeptSelect>-->
<!--              <el-link icon='el-icon-refresh' @click='loadMcnDeptList'></el-link>-->
<!--            </div>-->
<!--          </el-form-item>-->
          <el-form-item>
            <artist-search ref='refArtistSearch' @handleSelect='handleSelectKol'></artist-search>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='handlerQuery' icon='el-icon-search'>查询</el-button>
            <el-button @click.stop.native='reset'>重置</el-button>
<!--            <el-button style='display: none' @click='defaultSelectMcn'>默认MCN</el-button>-->
          </el-form-item>
          <el-form-item>
            <el-button :disabled='loadingInit' :loading='loadingInit' type='warning'
                       :title="`初始化【${activeMap[activeName] || '-' }目标】`" @click='handleInit'
                       v-if='hasAuthority("pa_kol_batch_setting")'>初始化
            </el-button>
          </el-form-item>
          <el-form-item>
            <export-link class-name='export-btn' v-if='hasAuthority("pa_kol_export")' ref='export' @export='exportExcel'
                         :can-export='true'
                         :title='`${activeMap[activeName]||"-"}目标`'
                         :lint-title='`导出`'
                         style='margin-left: 5px'>
            </export-link>
          </el-form-item>
          <el-form-item>
            <el-button v-if='hasAuthority("pa_kol_batch_setting")' plain :type='editMode?"primary":""'
                       @click='changeEditMode'>
              <span :class='editMode?"el-icon-check":"el-icon-edit-outline"'></span>
              {{ editMode ? '浏览' : '【编辑】' }} {{ activeMap[activeName] || '-' }}目标
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click='showBatch' icon='el-icon-setting' v-if='hasAuthority("pa_kol_batch_setting")'>
              批量设置{{ activeMap[activeName] || '-' }}目标
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-setting' @click='showSetting' v-if='hasAuthority("pa_kol_check_setting")'>考核设置
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-tabs v-model='activeName' class='performance-tab' tab-position='left' @tab-click='handleTabClick'>
          <el-tab-pane label='涨粉绩效目标' name='trend'>
            <PaTrend ref='trend' :edit-mode='editMode' @initEnd='initEnd'></PaTrend>
          </el-tab-pane>
          <el-tab-pane label='营收绩效目标' name='revenue'>
            <PaRevenue ref='revenue' :edit-mode='editMode'></PaRevenue>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div>
<!--      初始化-->
      <InitPanel ref='refInitPanel'></InitPanel>
      <!--      批量设置涨粉目标-->
      <BatchSetPaTrend ref='batchSetPaTrend' type-range='kol' @saved='handlerQuery'></BatchSetPaTrend>
      <!--      批量设置营收目标-->
      <BatchSetPaRevenue ref='batchSetPaRevenue' type-range='kol' @saved='handlerQuery'/>

      <CheckSetting :key='`CheckSetting_${activeName}`' ref='checkSetting' @savedSetting='handlerQuery' />
    </div>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import QuarterSelect from '@/components/time/QuarterSelect'
import PaTrend from '@/pages/pa/kol/PaTrend'
import PaRevenue from '@/pages/pa/kol/PaRevenue'
import ArtistSearch from '@/components/artist/ArtistSearch'
import BatchSetPaTrend from '@/pages/pa/components/BatchSetPaTrend'
import BatchSetPaRevenue from '@/pages/pa/components/BatchSetPaRevenue'
import ExportBtn from '@/components/export/ExportBtn'
import CheckSetting from '@/pages/pa/kol/CheckSetting'
import GroupSelect from '@/pages/groups/components/GroupSelect'
import McnDeptSelect from '@/pages/pa/kol/McnDeptSelect'
import ExportLink from '@/components/export/ExportLink'
import DeptCascader from './DeptCascader'
import InitPanel from '@/pages/pa/kol/InitPanel'

export default {
  name: 'PaKolIndex',
  components: {
    InitPanel,
    DeptCascader,
    ExportLink,
    McnDeptSelect,
    GroupSelect,
    CheckSetting,
    ExportBtn,
    BatchSetPaRevenue,
    BatchSetPaTrend,
    ArtistSearch,
    PaRevenue,
    PaTrend,
    QuarterSelect,
    PageHeaderLayout
  },
  data() {
    return {
      searchCondition: {
        quarter: null,
        year: null,
        artist_id: null,
        dept_ids: [],
        group_id: null,
        mcn_dept_id: null
      },
      activeName: 'trend',
      activeMap: { 'trend': '涨粉', 'revenue': '营收' },
      quarters: [
        { label: '未选季度', value: 0 },
        { label: '第一季度', value: 1 },
        { label: '第二季度', value: 2 },
        { label: '第三季度', value: 3 },
        { label: '第四季度', value: 4 }
      ],
      //编辑模式
      editMode: false,
      loadingInit: false,
      mcnDeptList: []
    }
  },
  mounted() {
    this.handleTabClick()
  },
  methods: {
    reset() {
      this.defaultQuarter()
      this.searchCondition.artist_id = null
      this.searchCondition.dept_ids = []
      if (this.$refs['refArtistSearch'])
        this.$refs['refArtistSearch'].clearSelect()

      if (this.$refs['refCompanySelect'])
        this.$refs['refCompanySelect'].clear()
      this.$nextTick(() => {
        if (this.activeName === 'trend') {
          this.$refs['trend'].clear(this.searchCondition)
        } else if (this.activeName === 'revenue') {
          this.$refs['revenue'].clear(this.searchCondition)
        }
      })

    },
    //默认选中部门
    async defaultSelectMcn() {
      // this.$refs['refCompanySelect'].init(['51361153'])
      let { list } = await this.$api.getPaMcnList(this.searchCondition)
      if (list) {
        let deptIds = list.map((item) => {
          return item.dept_id
        })
        this.$refs['refCompanySelect'].init(deptIds)
      }
    },
    handleChangeMcn(val) {
      console.log('val', val)
    },
    changeYear() {
      this.$nextTick(() => {
        this.loadMcnDeptList()
      })
      this.handlerQuery()
    },
    // eslint-disable-next-line no-unused-vars
    handleChangeQuarter(val) {
      // console.log('val',val)
      this.$nextTick(() => {
        this.loadMcnDeptList()
      })
      this.handlerQuery()
    },
    handlerQuery() {

      if (!(this.searchCondition.year && this.searchCondition.quarter)) {
        this.$notify.warning('时间必选')
        return false
      }
      // if (!(this.searchCondition.dept_ids && this.searchCondition.dept_ids.length > 0)) {
      //   this.$notify.warning('请至少选一个部门')
      //   return false
      // }
      this.handleTabClick()
    },
    handleTabClick() {
      if (this.searchCondition.year && this.searchCondition.quarter) {
        // console.log(JSON.stringify(this.searchCondition))
        if (this.activeName === 'trend') {
          this.$refs['trend'].show(this.searchCondition)
        } else if (this.activeName === 'revenue') {
          this.$refs['revenue'].show(this.searchCondition)
        } else {
          this.$notify.success(this.activeName)
        }
      }

      // this.$refs[this.activeName].show(this.searchCondition)
    },
    handleSelectKol(row) {
      // this.$notify.info(JSON.stringify(row))
      if (row)
        this.searchCondition.artist_id = row.id || null
      else
        this.searchCondition.artist_id = null

      this.handleTabClick()
    },
    selectDept(nodeIds) {
      // console.log('nodeIds', nodeIds)
      this.searchCondition.dept_ids = nodeIds
      this.handleTabClick()
    },
    handleInit() {
      if (!(this.searchCondition.year && this.searchCondition.quarter)) {
        this.$notify.warning('初始化时间必选')
        return
      }
      this.$refs['refInitPanel'].show(this.searchCondition)

    },
    initEnd() {
      this.loadingInit = false
    },
    changeEditMode() {
      //
      this.editMode = !this.editMode
    },
    showBatch() {
      let kolRange = []
      //批量设置
      if (this.activeName === 'trend') {
        kolRange = this.$refs['trend'].getCurrKolRange()
        if (kolRange.length === 0) {
          this.$notify.warning('请先选择有考核红人的项目组！！')
          return
        }
        this.$refs['batchSetPaTrend'].show(this.searchCondition, kolRange)
      } else if (this.activeName === 'revenue') {
        kolRange = this.$refs['revenue'].getCurrKolRange()
        if (kolRange.length === 0) {
          this.$notify.warning('请先选择有考核红人的项目组！！')
          return
        }
        this.$refs['batchSetPaRevenue'].show(this.searchCondition, kolRange)
      } else {
        this.$notify.success(this.activeName)
      }
    },
    async exportExcel() {
      //
      try {
        let fileName = `${this.searchCondition.year}年第${this.searchCondition.quarter}季度${this.activeMap[this.activeName]}绩效目标`

        if (this.activeName === 'trend') {
          let response = await this.$api.exportPaKolTrendQuarterData(this.searchCondition)
          this.$notify.warning(fileName)
          this.$refs['export'].exportSaveXlsx(response, fileName)
        } else if (this.activeName === 'revenue') {
          let response = await this.$api.exportPaKolRevenueQuarterData(this.searchCondition)
          this.$notify.warning(fileName)
          this.$refs['export'].exportSaveXlsx(response, fileName)
        } else {
          this.$notify.success(this.activeName)
        }


      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        this.$refs.export.hideLoading()
      }
    },
    loadDeptSetting(list) {

      if (!list || list.length === 0) {
        this.$confirm(`${this.searchCondition.year}年${this.searchCondition.quarter}季度考核部门未设置，是否设置`, '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
          this.showSetting()
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        })
      }
    },
    showSetting() {
      if (!this.hasAuthority('pa_kol_check_setting')) {
        this.$notify.success('无考核设置权限！！！')
        return
      }
      //设置考核
      if (this.activeName === 'trend') {
        this.$refs['checkSetting'].show(this.searchCondition, this.activeName)
      } else if (this.activeName === 'revenue') {
        this.$refs['checkSetting'].show(this.searchCondition, this.activeName)
      } else {
        this.$notify.success(this.activeName)
      }
    },
    defaultQuarter() {
      //当前季度
      this.searchCondition.year = (new Date()).getFullYear() + ''
      // 获取当前季度：
      this.searchCondition.quarter = this.$utils.getCurrentQuarter()
      this.$nextTick(() => {
        this.loadMcnDeptList()
      })
      //默认选中MCN中心
      this.defaultSelectMcn()

    },
    loadMcnDeptList() {
      if (this.$refs['deptSelect'])
        this.$refs['deptSelect'].getList()
    }
  },
  created() {
    this.defaultQuarter()
  }
}
</script>

<style scoped>

</style>
