<template>
  <div style='display: inline-block;'>
    <el-cascader
      v-model='value'
      :options='options'
      :placeholder="`请选择部门(${searchCondition.year}年第${searchCondition.quarter}季度)`"
      :props="{expandTrigger: 'hover',value: valueType,label: 'name',multiple:multiple,checkStrictly: true}"
      clearable
      collapse-tags style='width: 400px' @change='handleChange'></el-cascader>
  </div>
</template>

<script>
export default {
  name: 'McnDeptSelect',
  data() {
    return {
      data: [],
      options: [],
      deptProps: { value: 'dpt_id', label: 'name', children: 'children' },
      checkStrictly: false,
      filterText: '',
      selection: null,
      checkList: [],
      value: null
    }
  },
  props: {
    searchCondition: {
      type: Object
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    size: {
      type: String,
      default() {
        return 'mini'
      }
    },
    showFirstGroup: {
      type: Boolean,
      default() {
        return false
      }
    },
    valueType: {
      type: String,
      default() {
        return 'dpt_id'
      }
    }

  },
  watch: {
    filterText(val) {
      this.$refs.deptTree.filter(val)
    }
  },
  methods: {
    //初始化
    init(val) {
      this.value = val ? val : null
    },
    //根据部门名称筛选【一般用于外部调用：指定部门】
    changeDeptByName(name) {
      let dept = this.data.find(value => value.name === name)
      if (dept) {
        this.value = dept.dpt_id
        this.handleChange([dept.parent_id, dept.dpt_id])
      }
    },
    handleChange(selectArr) {
      let deptArr = []

      if (this.multiple) {
        selectArr.forEach((deptId) => {
          let dept = deptId[deptId.length - 1]
          deptArr.push(dept)
        })
      } else {
        deptArr.push(selectArr[selectArr.length - 1])
      }

      // console.log('handleSelect',deptArr)
      this.$emit('handleSelect', deptArr)
      return selectArr
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    async getList() {
      let params = {
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter
      }
      let { list } = await this.$api.getPaKolTrendAllowDeptList(params)
      this.data = list || []
      //向父组件汇报加载的部门列表
      this.$emit('loaded', list)
      this.calcOptions()

    },
    calcOptions() {
      if (this.data) {
        this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')
        if (this.options.length > 0) {
          let dept = this.options[0]
          //计算第一个项目组
          this.calcLastDept(dept)

        }
      } else {
        this.options = []
      }

    },
    calcLastDept(dept) {
      if (dept && dept['children']) {
        let firstChildDept = dept['children'][0]
        if (firstChildDept) {
          this.calcLastDept(firstChildDept)
        }
      } else {
        // console.log(dept)
        if (!this.multiple) {
          //单选
          this.value = dept.dpt_id || null
          // let deptSelect = this.data.find(value => value.dpt_id === this.value)
          // console.log(deptSelect)
          this.$emit('handleSelect', [this.value])
        }
      }
    },
    getNodeName(val) {
      let obj = this.data.find(value => value.dpt_id === val)
      return obj ? obj.name : ''
    },
    //部门原始数据
    getNodeData() {
      return this.data
    }
  },
  created() {
    // this.getList()
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
