<template>
  <div>
    <div v-show='visible' class='menu' ref='moveBox'>
      <div style='margin-left: 0'>
        <el-tooltip effect='dark' content='拖动' placement='top-start'>
          <span class='drag-handle' @mousedown='mouseDownHandle($event)'
                @mouseup='mouseUpHandle($event)'><i
            class='el-icon-rank'></i></span>
        </el-tooltip>
        <span class='title'>{{ currentRow.nickname }}/{{ currentRow.platform_name }}-收入分类</span>
        <div class='check-all'>
          <el-checkbox :indeterminate='isIndeterminate' v-model='checkAll' @change='handleCheckAllChange'
                       class='checkbox-color'>全选
          </el-checkbox>
        </div>

        <el-checkbox-group v-model='selectedOptions' @change='handleCheckedChange'>
          <ul>
            <li v-for='(val,label) in options' :key='val'>
              <el-checkbox :label='label' class='checkbox-color'>
                {{ val }}
              </el-checkbox>
            </li>
          </ul>
        </el-checkbox-group>
        <div style='text-align: center;width: 100%;margin-top: 10px'>
          <el-button type='primary' size='mini' @click='handleSave'>保存</el-button>
          <el-button size='mini' @click='closeMenu'>关闭</el-button>
        </div>
        <!--        <div style="margin-top: 20px">-->
        <!--          <span style="margin-inline-start: 5px;">PS：从有账号的平台中选择要考核平台</span>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaIncomeSetting',
  props: {
    performanceType: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      moveDataelse: {
        x: null,
        y: null
      },
      //start:鼠标右键菜单
      visible: false,
      top: 0,
      left: 0,
      //end:鼠标右键菜单
      currentRow: {},
      currentColumn: {},
      options: [],//红人所有平台
      allOptions: [],//红人所有平台
      selectedOptions: [],//要显示的平台
      checkAll: true,
      isIndeterminate: false
    }
  },
  created() {

  },
  methods: {
    async init(platformsMap, incomeTypes, currentRow) {
      this.visible = true
      this.$nextTick(() => {
        this.currentRow = currentRow
        this.options = incomeTypes
        this.selectedOptions = this.calcIncomeTypeSelected(currentRow)

        this.isIndeterminate = this.selectedOptions.length > 0 && this.selectedOptions.length < this.allOptions.length
        this.checkAll = this.selectedOptions.length === this.allOptions.length
      })


    },
    async handleNeedCheckSelection(rows) {

      await this.$api.changePaKolRevenueQuarterNeedCheck({ multiple: rows })
      this.$notify.success('保存成功')
      this.closeMenu()
      this.$emit('savedEvent')
    },
    calcIncomeTypeSelected(currentRow) {
      let selected = []
      let children = currentRow.children
      this.allOptions = []
      if (children) {
        Object.keys(children).forEach((income_code) => {
          let item = children[income_code]
          this.allOptions.push(item.income_code)
          // let currIncomeType = item['income_code']
          if (item.need_check === 'Y') {
            selected.push(item.income_code)
          }
        })
      }

      return selected
    },
    async handleSave() {
      let saveRows = []
      if (this.currentRow.children) {
        let children = this.currentRow.children
        Object.keys(children).forEach((income_code) => {
          let item = children[income_code]
          let currIncomeType = item['income_code']
          if (this.selectedOptions.indexOf(currIncomeType) > -1) {
            item.need_check = 'Y'
          } else {
            item.need_check = 'N'
          }
          saveRows.push(item)

        })
      }
      await this.handleNeedCheckSelection(saveRows)

    },
    handleCheckAllChange(val) {
      this.selectedOptions = val ? this.allOptions : []
      this.isIndeterminate = false
    },
    handleCheckedChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.allOptions.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allOptions.length
    },
    closeMenu() {
      this.visible = false
    },
    mouseDownHandle(event) {
      this.$nextTick(() => {
        this.moveDataelse.x = event.pageX - this.$refs['moveBox'].offsetLeft
        this.moveDataelse.y = event.pageY - this.$refs['moveBox'].offsetTop
        event.currentTarget.style.cursor = 'move'
        window.onmousemove = this.mouseMoveHandle
      })

    },
    mouseMoveHandle(event) {
      this.$nextTick(() => {
        let moveLeft = event.pageX - this.moveDataelse.x + 'px'
        let moveTop = event.pageY - this.moveDataelse.y + 'px'
        this.$refs['moveBox'].style.left = moveLeft
        this.$refs['moveBox'].style.top = moveTop
      })

    },
    mouseUpHandle(event) {
      window.onmousemove = null
      event.currentTarget.style.cursor = 'move'
    }
  }
}
</script>

<style lang='scss' scoped>
.menu {
  //background-color: rgba(255, 255, 255, 0.7);
  background-color: rgba(48, 49, 52, 0.7);
  border: solid 1px #f2f2f2;
  margin: 0;
  //background: #fff;
  z-index: 99999;
  position: fixed;
  list-style-type: none;
  padding: 15px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  height: 400px;
  width: 400px;
  //color: #333;
  color: #f2f2f2 !important;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    border-top: solid 1px #f2f2f2;
  }

  li {
    margin: 5px 0 2px 0;
    padding: 7px 10px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 49, 118, 0.7);
      //background: rgba(48, 49, 52, 0.7);
    }
  }

  .title {
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    padding-inline-start: 12px;
    padding-inline-end: 10px;
    margin-bottom: 20px;
    margin-inline-start: 1px;
    margin-inline-end: 10px;
  }

  .drag-handle {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
  }

}

.checkbox-color {
  color: #f2f2f2;
}

.check-all {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  border-top: solid 1px #f2f2f2;
  margin: 5px 0 2px 0;
  padding: 7px 30px;
  cursor: pointer;

}
</style>
