<template>
  <el-dialog :visible.sync='dialogVisible' v-bind='$attrs' v-on='$listeners'
             :title='title' width='980px'>
    <div class='flex-sb shadow-effect padding-effect' style='margin-bottom: 8px'>
      <div>
        <el-checkbox-group
          v-model='checkedOptions'>
          <el-checkbox v-for='option in options' :label='option' :key='option'>{{ option }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <el-button round type='primary' icon='el-icon-refresh' @click='handleInitData' :disabled='loadingCommit'
                 :loading='loadingCommit'>
        {{ loadingCommit ? '初始化中，请不要关闭弹窗' : '初始化' }}
      </el-button>
    </div>
    <div class='flex-sb'>
      <div class='left' style='width: 400px'>
        <div class='default-table'>
          <div class='shadow-effect padding-effect'>
            <h3>《{{ this.searchCondition.year }}年第{{ this.searchCondition.quarter }}季度在约红人：{{ total }}位》</h3>
          </div>
          <el-table :data='dataList' :span-method='objectSpanMethod' max-height='500' border>
            <el-table-column label='序号' type='index'></el-table-column>
            <el-table-column label='部门' prop='group_name' align='center' min-width='120'></el-table-column>
            <el-table-column label='红人昵称' prop='nickname' min-width='200'></el-table-column>
            <!--        <el-table-column label='涨粉' prop='trend'></el-table-column>-->
            <!--        <el-table-column label='营收' prop='revenue'></el-table-column>-->
          </el-table>
          <div class='flex-sb'>
            <el-link @click='getList' icon='el-icon-refresh'>刷新</el-link>
            <div>
              <m-pagination @pagination='getList' :total.sync='total' :limit.sync='pageData.page_size'
                            :page.sync='pageData.current_page' :layout='pageLayout'></m-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class='right' style='width: 520px;height: 620px;border: #f2f2f2 1px dashed;border-radius: 10px;padding: 5px;
      display:flex;flex-direction: column;justify-content: flex-start'>
        <div style='text-align: center;width: 100%;height: 100%'>
          <div>
            <el-link @click='getDataSummary' type='primary'>绩效目标数据:{{ dataMap.count }}条</el-link>
          </div>
          <div>
            <el-table :data='dataMap.list' :default-sort="{prop: 'month', order: 'descending'}" max-height='600'>
              <!--              <el-table-column label='年份' prop='year' width='80px' sortable>-->
              <!--                <template slot-scope='{row}'>{{ row.year }}年 </template>-->
              <!--              </el-table-column>-->
              <el-table-column label='月份' prop='month' min-width='90px' sortable>
                <template slot-scope='{row}'>{{ row.year }}年 {{ row.month }}月</template>
              </el-table-column>
              <el-table-column label='分类' prop='pa_type' min-width='100px' sortable>
                <template slot-scope='{row}'>{{ dataMap.paTypeMap[row.pa_type] || '' }}</template>
              </el-table-column>
              <el-table-column label='考核项' prop='pa_option' min-width='100px' sortable>
                <template slot-scope='{row}'>{{ dataMap.paOptionMap[row.pa_option] || '' }}</template>
              </el-table-column>
              <el-table-column label='数量' prop='t' min-width='80px' sortable></el-table-column>
              <el-table-column label='考核' prop='need_check' min-width='70px' sortable>
                <template slot-scope='{row}'>{{ row.need_check === 'Y' ? '是' : '否' }}</template>
              </el-table-column>
              <el-table-column label='发布' prop='release_status' min-width='70px' sortable>
                <template slot-scope='{row}'>{{ row.release_status === 'Y' ? '已发布' : '编辑中' }}</template>
              </el-table-column>
            </el-table>

          </div>

        </div>

      </div>
    </div>

  </el-dialog>
</template>

<script>
export default {
  name: 'InitPanel',
  data() {
    return {
      dialogVisible: false,
      loading: false,
      loadingCommit: false,
      dataList: [],
      searchCondition: {
        year: null, quarter: null
      },
      pageData: { page_size: 100, current_page: 1 },
      total: 0,
      pageLayout: 'total, prev, pager, next',
      title: '初始化',
      mergeFields: ['group_name'],
      spanObj: [],
      dept_ids: [],
      checkedOptions: ['红人绩效目标', '项目组绩效目标', '总监绩效目标'],
      options: ['红人绩效目标', '项目组绩效目标', '总监绩效目标'],
      progress: [],
      paTypeMap: {},
      dataMap: {
        list: [],
        paTypeMap: {},
        paOptionMap: {},
        count: 0
      }
    }
  },
  methods: {
    show(query) {
      this.dialogVisible = true
      this.searchCondition = { ...query }
      this.title = `初始化${this.searchCondition.year}年第${this.searchCondition.quarter}季度红人的涨粉和营收目标`
      this.getList()
      this.getDataSummary()
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      // 在约红人
      this.$notify.info(`加载${this.searchCondition.year}年第${this.searchCondition.quarter}季度在约红人`)
      //kol_range
      let params = { ...this.searchCondition }
      Object.assign(params, this.pageData)
      let { list, pages } = await this.$api.getPaGroupSettingKolRange(params)
      this.dataList = list || []
      this.total = pages.total || 0
      this.pageData.current_page = pages.current_page || 0
      this.pageData.page_size = pages.page_size || 0
      this.rowspan()

      this.dept_ids = list.map((item) => {
        return item.group_id
      })
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.spanObj = {}
      let positionObj = {}
      if (this.dataList && this.dataList.length > 0) {
        this.dataList.forEach((item, index) => {
          fields.forEach((field) => {
            if (index === 0) {
              this.spanObj[field] = []
              this.spanObj[field].push(1)
              positionObj[field] = 0
            } else {
              // 合并数据：若和上一行的数据相同，则该字段合并行数加1
              if (this.dataList[index][field] === this.dataList[index - 1][field]) {
                this.spanObj[field][positionObj[field]] += 1
                this.spanObj[field].push(0)
              } else {
                this.spanObj[field].push(1)
                positionObj[field] = index
              }
            }
          })
        })
      }

    },
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      const fields = this.mergeFields
      if (column.property && fields.indexOf(column.property) > -1) {
        const _row = this.spanObj[column.property][rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }

    },
    handleInitData() {
      if (!(this.searchCondition.year && this.searchCondition.quarter)) {
        this.$notify.warning('初始化时间必选')
        return
      }
      // if (!(this.dept_ids && this.dept_ids.length > 0)) {
      //   this.$notify.warning('部门必须')
      //   return
      // }
      this.$confirm(`确认初始化绩效目标：${this.searchCondition.year}年第${this.searchCondition.quarter}季度？`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        this.loadingCommit = true
        let params = {
          year: this.searchCondition.year, quarter: this.searchCondition.quarter
        }
        if (this.checkedOptions.indexOf('红人绩效目标') > -1) {
          await this.$api.initPaKolTrendQuarterData(params)
          // this.$notify.success('涨粉绩效目标初始化')
          await this.$api.initPaKolRevenueQuarterData(params)
        }

        // this.$notify.success('营收绩效目标初始化')
        //初始化项目组
        if (this.checkedOptions.indexOf('项目组绩效目标') > -1)
          await this.$api.syncPaGroupSetting(params)
        // this.$notify.success('营收绩效目标初始化')

        if (this.checkedOptions.indexOf('总监绩效目标') > -1)
          await this.$api.initPaChiefMcnQuarterData(params)

        this.loadingCommit = false
        // this.dialogVisible = false
        await this.getDataSummary()

      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      })
    },
    async getDataSummary() {
      let params = { year: this.searchCondition.year, quarter: this.searchCondition.quarter }
      this.dataMap = await this.$api.getPaDataSummary(params)
    }
  }
}
</script>

<style scoped>

</style>
